<template>
  <CModalDev
    :show.sync="boo_modal"
    :closeOnBackdrop="true"
    :centered="true"
    title="Modal title 2"
    :size="size"
    color="dark"
  >
    <CRow>
      <CCol lg="12" md="12" sm="12" xs="12">
        <label for="">Cliente</label>
        <v-select
          :options="data.clients"
          placeholder="Seleccione destino"
          v-model="appointment.client"
        />
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12" class="mt-1">
        <label for="">Servicio</label>
        <v-select
          :options="data.services"
          placeholder="Seleccione destino"
          v-model="appointment.service"
        />
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12" class="mt-1">
        <CInput label="Fecha" type="date" v-model="data.date" />
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12" class="mt-1">
        <CInput label="Hora" type="time" v-model="appointment.time" />
      </CCol>
    </CRow>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cancelar</CButton>
      <CButton @click="mtd_commit" color="success">Aceptar</CButton>
    </template>
  </CModalDev>
</template>

<script>
import CModalDev from "../../components/shared/modals/cModalDev.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "c-modal-delete",
  components: { CModalDev, vSelect },
  props: {
    title: String,
    boo_modal: Boolean,
    data: [],
    size: String,
  },
  data() {
    return {
      appointment: {
        id: "",
        client: [],
        service: [],
        date: "",
        time: "",
        state: "",
      },
    };
  },
  methods: {
    mtd_close() {
      this.$emit("close_delete");
    },
    mtd_commit() {
      this.appointment.date = this.data.date;
      this.$emit("mtd_commit", this.appointment);
      this.appointment = {
        id: "",
        client: [],
        service: [],
        date: "",
        time: "",
        state: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>