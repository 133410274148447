<template>
  <CModalDev
    :show.sync="boo_modal"
    :closeOnBackdrop="true"
    :centered="true"
    title="Modal title 2"
    :size="size"
    color="info"
  >
    <CRow>
      <CCol
        sm="12"
        xs="12"
        md="12"
        lg="12"
        class="d-flex justify-content-center text-center"
      >
        <span
          ><strong>{{ data.title }}</strong>
        </span>
      </CCol>
      <CCol
        sm="12"
        xs="12"
        md="4"
        lg="4"
        class="d-flex justify-content-center text-center mt-2"
      >
        <CInput size="sm" label="Precio" type="text" @blur="mtdOutFocusPrices" v-on:keypress="isNumber($event)" placeholder="Precio" style="text-align: center;" v-model="data.price" />
      </CCol>
      <CCol
        sm="12"
        xs="12"
        md="4"
        lg="4"
        class="d-flex justify-content-center text-center  mt-2"
      >
        <CInput size="sm" label="Pagó" type="text" @blur="mtdOutFocusPrices" v-on:keypress="isNumber($event)" placeholder="Precio" style="text-align: center;" v-model="data.amount" />
      </CCol>
      <CCol
        sm="12"
        xs="12"
        md="4"
        lg="4"
        class="d-flex justify-content-center text-center  mt-2"
      >
        <CInput size="sm" label="Saldo" type="text" placeholder="Precio" style="aling-items:center" v-model="cpSaldo" disabled />
      </CCol>
      <CCol
        sm="12"
        xs="12"
        md="12"
        lg="12"
        class=""
      >
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Tipo pago</th>
              <th>Monto</th>
              <th>Transacción</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data.payments" :key="index">
              <td>{{index + 1}}</td>
              <td>{{item.text}}</td>
              <td>
                <input type="text" @blur="mtdOutPayment(index)" v-model="item.amount" placeholder="Monto" class="form-control form-control-sm">
              </td>
              <td>
                <input :disabled="!item.transaction" type="text" v-model="item.num_transaction" placeholder="Transacción" class="form-control form-control-sm">
              </td>
            </tr>
          </tbody>
        </table>
      </CCol>
    </CRow>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cerrar</CButton>
      <CButton @click="mtd_commit" color="info">Terminar</CButton>
    </template>
  </CModalDev>
</template>

<script>
import { bus } from "../../main";

import CModalDev from "../../components/shared/modals/cModalDev.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "c-modal-end-cita",
  components: { CModalDev, vSelect },
  props: {
    title: String,
    boo_modal: Boolean,
    data: [],
    size: String,
  },
  computed: {
    cpSaldo(){
        if (parseFloat(this.data.price) > 0 && parseFloat(this.data.amount) > 0) {
            if (parseFloat(this.data.amount) > parseFloat(this.data.price)) {
                this.data.saldo = 0.00;
            } else {
                this.data.saldo = parseFloat(parseFloat(this.data.price) - parseFloat(this.data.amount) ).toFixed(2);
            }
        }else{
            this.data.saldo = 0.00;
        }
        return this.data.saldo;
    }
  },
  data() {
    return {     
    };
  },
  methods: {
    mtd_close() {
      this.$emit("close_end");
    },
    mtd_commit() {
      this.$emit("mtd_commit_end",this.data);
    },
    mtdOutFocusPrices: function(){
        this.data.price = this.data.price>0? parseFloat(this.data.price).toFixed(2):0.00;
        this.data.amount = this.data.amount>0? parseFloat(this.data.amount).toFixed(2):0.00;
        if (parseFloat(this.data.amount) > parseFloat(this.data.price)) {
            this.data.amount = 0.00;
            bus.$emit("alert", {
              color: "warning",
              message: "El monto pagado no puede ser mayor al precio!!",
            });
        }
        this.data.payments=[
          {text:'Efectivo',amount:0,transaction:false, num_transaction:""},
          {text:'Yape',amount:0,transaction:true, num_transaction:""},
          {text:'Plin',amount:0,transaction:true, num_transaction:""},
          {text:'Transferencia',amount:0,transaction:true, num_transaction:""},
          {text:'Tarjeta',amount:0,transaction:true, num_transaction:""},
        ];
    },
    mtdOutPayment: function(pos){
      if (this.data.amount>0) {
        let subtotal = 0;
        this.data.payments.forEach(element => {
          subtotal += parseFloat(element.amount);
        });
        if (subtotal > this.data.amount ) {
          this.data.payments[pos].amount=0.00;
          bus.$emit("alert", {
            color: "warning",
            message: "La suma de montos no puede ser mayor al monto paado!!",
          });
        }
      }else{
        this.data.payments[pos].amount=0.00;
        bus.$emit("alert", {
          color: "warning",
          message: "El monto pagado debe ser mayor a cero!!",
        });
      }
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>