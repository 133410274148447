<template>
  <CSidebar
    aside
    :show="$store.state.asideShow"
    @update:show="(val) => $store.commit('set', ['asideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('closeAside')"/>
    <br><br>
    <div class="container">
      <CRow>
        <CCol md="6" lg="6" sm="12" xs="12">
            <h3>TOTAL  </h3>
        </CCol>
        <CCol md="6" lg="6" sm="12" xs="12">
            <h3>
            <CBadge
                color="info"
            >S/{{data.price}}</CBadge>
            </h3>
        </CCol>
        <CCol md="6" lg="6" sm="12" xs="12">
            <h3>PAGADO  </h3>
        </CCol>
        <CCol md="6" lg="6" sm="12" xs="12">
            <h3>
            <CBadge
                color="success"
            > S/{{data.amount}}</CBadge>
            </h3>
        </CCol>
        <CCol md="6" lg="6" sm="12" xs="12">
            <h3>SALDO  </h3>
        </CCol>
        <CCol md="4" lg="4" sm="12" xs="12">
            <h3>
            <CBadge
                color="warning"
            >S/{{data.saldo}}</CBadge>
            </h3>
        </CCol>
        <CCol md="12" lg="12" sm="12" xs="12" class="text-center">
          <h4><b>Pagos de cita</b></h4>
        </CCol>
        <CCol md="12" lg="12" sm="12" xs="12" class="text-center">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Método</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in JSON.parse(data.charge[0].charge)" :key="index">
                <td>{{index+1}}</td>
                <td>{{item.text}}</td>
                <td>{{parseFloat(item.amount).toFixed(2)}}</td>
              </tr>
            </tbody>
          </table>
        </CCol>
        <CCol v-if="data.charge.length > 1" md="12" lg="12" sm="12" xs="12" class="text-center">
          <button class="btn btn-info btn-sm">Ver Todos los pagos</button>
        </CCol>
      </CRow>
    </div>
  </CSidebar>
</template>

<script>
export default {
  name: 'TheAside',
  props:{
    data:{},
  },
  computed: {
    cpTotal(){
      
    }
  },
}
</script>
