<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol lg="12" md="12" sm="12" xs="12">
            <FullCalendar :options="calendarOptions" ref="refCalendar" />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <cAsidePaymentVue :data="dataAside" />
    <!-- modal nueva cita -->
    <cModalNewCita
      title="Nueva cita"
      :boo_modal="modal_new.boo"
      :data="modal_new.data"
      @close_delete="close_new"
      :size="'md'"
      @mtd_commit="mtd_commit_new"
    ></cModalNewCita>

    <!-- modal procesar cita -->
    <cModalProcessCita
      title="Procesar cita"
      :boo_modal="modal_proccess.boo"
      :data="modal_proccess.data"
      @close_proccess="close_proccess"
      :size="'md'"
      @mtd_commit_proccess="mtd_commit_proccess"
    ></cModalProcessCita>

    <!-- modal end cita -->
    <cModalEndCita
      title="Finalizar cita"
      :boo_modal="modal_end.boo"
      :data="modal_end.data"
      @close_end="close_end"
      :size="'lg'"
      @mtd_commit_end="mtd_commit_end"
    ></cModalEndCita>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import SelectDev from "../../components/shared/inputs/cSelect.vue";
import cModalNewCita from "../../components/appointment/cModalNewCita.vue";
import cModalProcessCita from "../../components/appointment/cModalProccessCita.vue";
import cModalEndCita from '../../components/appointment/cModalEndCita.vue';
import cAsidePaymentVue from '../../components/appointment/cAsidePayment.vue';

export default {
  components: {
    FullCalendar,
    SelectDev,
    cModalNewCita,
    cModalProcessCita,
    cModalEndCita,
    cAsidePaymentVue
  },
  data() {
    return {
      calendarOptions: {
        // editable: true,
        // droppable: true,
        // eventDrop: function (info) {
        //   console.log(info);
        // },
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        locale: "es",
        firstDay: 1,
        initialView: "dayGridMonth",
        // validRange: {
        //     start: '2023-01-09',
        // },
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        events:[],
        // events: {
        //   url: "/api/v1/appointment",
        //     success: this.handleSuccessRequestEvent,
        // },
        titleFormat: {
          month: "long",
          year: "numeric",
          day: "numeric",
        },
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          hour24: true,
        },
        buttonText: {
          today: "Hoy",
          month: "Meses",
          week: "Semanas",
          day: "Días",
          list: "Lista",
        },
      },
      modal_new: {
        boo: false,
        data: {
          clients: [],
          services: [],
          date: "",
        },
      },
      appointment: {
        id: "",
        client: [],
        service: [],
        date: "",
        time: "",
        state: "",
      },
      /** procesar citas */
      modal_proccess:{
        boo: false,
        data: {
          id:"",
          state: "",
          dates:"",
          title:"",
          vencido:false,
          phone:"",
          price:""
        },
      },
      /** terminar cita */
      modal_end:{
        boo: false,
        data: {
          id:"",
          title:"",
          price:"",
          amount:"",
          saldo:"",
          payments:[
            {text:'Efectivo',amount:0,transaction:false, num_transaction:""},
            {text:'Yape',amount:0,transaction:true, num_transaction:""},
            {text:'Plin',amount:0,transaction:true, num_transaction:""},
            {text:'Transferencia',amount:0,transaction:true, num_transaction:""},
            {text:'Tarjeta',amount:0,transaction:true, num_transaction:""},
          ]
        },
      },
      /** aside */
      dataAside:[]
    };
  },
  computed: {
    cp_getEvents(){
      return this.events;
    }
  },
  created() {
    this.mtdGetData();
  },
  mounted() {
    this.mtd_getEvent();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getEvent: function(){
      this.get({
        url: this.$store.getters.get__url + "/appointment",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.calendarOptions.events=response;
        })
        .catch((errors) => {});
    },
    mtdGetData: function () {
      this.get({
        url: this.$store.getters.get__url + "/appointment/getData",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.modal_new.data.clients = response.clients;
          this.modal_new.data.services = response.services;
        })
        .catch((errors) => {});
    },
    close_new: function () {
      this.modal_new.boo = false;
    },
    mtd_commit_new: function (props) {
      this.appointment = props;
      this.post({
        url: this.$store.getters.get__url + "/appointment/store",
        token: this.$store.getters.get__token,
        params: this.appointment,
      })
        .then((response) => {
          // console.log(response);
          this.calendarOptions.events.push(response.data)
        })
        .catch((errors) => {});
      this.close_new();
    },
    handleDateClick: function (arg) {
      //VALIDO QUE LA FECHA SEA MAYOR A LA ACTUAL
      const date = new Date(arg.date);
      const dateCurrent = new Date();
      dateCurrent.setHours(0);
      dateCurrent.setMinutes(0);
      dateCurrent.setSeconds(0);
      dateCurrent.setMilliseconds(0);
      if (date < dateCurrent) {
        bus.$emit("alert", {
          color: "warning",
          message: "No puede seleccionar una fecha pasada",
        });
      } else {
        this.modal_new.boo = true;
        this.modal_new.data.date = arg.dateStr;
      }
    },
    handleEventClick: function (arg) {
      let vencido=false;
      //VALIDO QUE LA FECHA SEA MAYOR A LA ACTUAL
      const date = new Date(arg.end);
      const dateCurrent = new Date();
      dateCurrent.setHours(0);
      dateCurrent.setMinutes(0);
      dateCurrent.setSeconds(0);
      dateCurrent.setMilliseconds(0);
      if (date < dateCurrent) {
        vencido = true;
      } else {
        vencido = false;
      }
      if (arg.event.extendedProps.state < 4) {
        this.modal_proccess.boo = true;
        this.modal_proccess.data.id=arg.event.id;
        this.modal_proccess.data.state=arg.event.extendedProps.state;
        this.modal_proccess.data.dates=arg.event.extendedProps.dates;
        this.modal_proccess.data.phone=arg.event.extendedProps.phone;
        this.modal_proccess.data.title=arg.event.title;
        this.modal_proccess.data.vencido = vencido;
        this.modal_proccess.data.price = arg.event.extendedProps.price;
      }else{
        this.$store.commit('toggle', 'asideShow')
        console.log(arg.event.extendedProps.payment);
        this.dataAside = arg.event.extendedProps.payment[0];
      }
      
      // console.log(this.modal_proccess);
    },
    close_proccess: function(){
      this.modal_proccess = {
        boo: false,
        data: {
          id:"",
          state: "",
          dates:"",
          title:"",
          vencido:false,
          phone:""
        }
      };
    },
    mtd_commit_proccess: function(newState,id){
      if(newState != 4 ){
        this.post({
          url: this.$store.getters.get__url + "/appointment/proccess",
          token: this.$store.getters.get__token,
          params:{
            state:newState,
            id:id
          }
        })
          .then((response) => {
            if (response.state == 0) {
              let msj="";let color="";let textColor="";
              if (newState == 2) {msj = "Cita confirmada correctamente";color = 'green'; textColor='white'}
              bus.$emit("alert", {
                color: "success",
                message: msj,
              });
              this.mtd_getEvent();
              this.close_proccess();
            } else {
              bus.$emit("alert", {
                color: "danger",
                message: response.message,
              });
            }
          })
          .catch((errors) => {});
      }else{
        // alert(this.modal_proccess.data.price);
        this.modal_end.data.id=this.modal_proccess.data.id;
        this.modal_end.data.title=this.modal_proccess.data.title;
        this.modal_end.data.price=this.modal_proccess.data.price;
        this.modal_end.boo = true;
        this.close_proccess();
      }
    },
    close_end: function(){
      this.modal_end = {
        boo: false,
        data: {
          id:"",
          title:"",
          price:"",
          amount:"",
          saldo:"",
          payments:[
            {text:'Efectivo',amount:0,transaction:false, num_transaction:""},
            {text:'Yape',amount:0,transaction:true, num_transaction:""},
            {text:'Plin',amount:0,transaction:true, num_transaction:""},
            {text:'Transferencia',amount:0,transaction:true, num_transaction:""},
            {text:'Tarjeta',amount:0,transaction:true, num_transaction:""},
          ]
        }
      }
    },
    mtd_commit_end: function(data){
      this.post({
          url: this.$store.getters.get__url + "/appointment/payment",
          token: this.$store.getters.get__token,
          params:data
        })
          .then((response) => {
            console.log(response);
            if (response.state == 0) {
              bus.$emit("alert", {
                color: "success",
                message: "Cita finalizada correctamente!",
              });
              this.mtd_getEvent();
              this.close_end();
            } else {
              bus.$emit("alert", {
                color: "warning",
                message: response.message,
              });
            }
          })
          .catch((errors) => {
            bus.$emit("alert", {
              color: "danger",
              message: errors,
            });
          });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
