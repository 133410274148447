var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CModalDev',{attrs:{"show":_vm.boo_modal,"closeOnBackdrop":true,"centered":true,"title":"Modal title 2","size":_vm.size,"color":"info"},on:{"update:show":function($event){_vm.boo_modal=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.title))]),_c('CButtonClose',{staticClass:"text-white",on:{"click":_vm.mtd_close}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":_vm.mtd_close}},[_vm._v("Cerrar")])]},proxy:true}])},[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-center text-center",attrs:{"sm":"12","xs":"12","md":"12","lg":"12"}},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.data.title))])])]),_c('CCol',{staticClass:"d-flex justify-content-center",attrs:{"sm":"12","xs":"12","md":"12","lg":"12"}},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.data.phone))])])]),_c('CCol',{staticClass:"d-flex justify-content-center mt-2",attrs:{"sm":"12","xs":"12","md":"12","lg":"12"}},[(_vm.data.state == 1)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:({
          content: `Confirmar Cita`,
        }),expression:"{\n          content: `Confirmar Cita`,\n        }",modifiers:{"hover":true}}],staticClass:"btn-sm ml-1",attrs:{"color":"info"},on:{"click":function($event){return _vm.mtd_proccess(2)}}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Confirmar Cita ")]):_vm._e(),(_vm.data.state == 2)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:({
          content: `Empezar Cita`,
        }),expression:"{\n          content: `Empezar Cita`,\n        }",modifiers:{"hover":true}}],staticClass:"btn-sm ml-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.mtd_proccess(3)}}},[_c('i',{staticClass:"fas fa-spinner"}),_vm._v(" Empezar Cita ")]):_vm._e(),(_vm.data.state == 3)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:({
          content: `Terminar Cita`,
        }),expression:"{\n          content: `Terminar Cita`,\n        }",modifiers:{"hover":true}}],staticClass:"btn-sm ml-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.mtd_proccess(4)}}},[_c('i',{staticClass:"fas fa-spinner"}),_vm._v(" Terminar Cita ")]):_vm._e(),(_vm.data.state == 1 || _vm.data.state == 2)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:({
          content: `Cancelar`,
        }),expression:"{\n          content: `Cancelar`,\n        }",modifiers:{"hover":true}}],staticClass:"btn-sm ml-1",attrs:{"color":"danger"}},[_c('i',{staticClass:"fas fa-trash"}),_vm._v(" Cancelar Cita ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }