<template>
  <CModalDev
    :show.sync="boo_modal"
    :closeOnBackdrop="true"
    :centered="true"
    title="Modal title 2"
    :size="size"
    color="info"
  >
    <CRow>
      <CCol sm="12" xs="12" md="12" lg="12" class="d-flex justify-content-center text-center">
        <span><strong>{{data.title}}</strong> </span>
      </CCol>
      <CCol sm="12" xs="12" md="12" lg="12" class="d-flex justify-content-center">
        <span><strong>{{data.phone}}</strong> </span>
      </CCol>
      <CCol sm="12" xs="12" md="12" lg="12" class="d-flex justify-content-center mt-2">
        <CButton
          v-if="data.state == 1"
          class="btn-sm ml-1"
          color="info"
          v-c-tooltip.hover="{
            content: `Confirmar Cita`,
          }"
          @click="mtd_proccess(2)"
        >
          <i class="fa fa-check"></i> Confirmar Cita
        </CButton>
        <CButton
          v-if="data.state == 2"
          class="btn-sm ml-1"
          color="warning"
          v-c-tooltip.hover="{
            content: `Empezar Cita`,
          }"
          @click="mtd_proccess(3)"
        >
          <i class="fas fa-spinner"></i> Empezar Cita
        </CButton>
        <CButton
          v-if="data.state == 3"
          class="btn-sm ml-1"
          color="warning"
          v-c-tooltip.hover="{
            content: `Terminar Cita`,
          }"
          @click="mtd_proccess(4)"
        >
          <i class="fas fa-spinner"></i> Terminar Cita
        </CButton>
        <CButton
         v-if="data.state == 1 || data.state == 2"
          class="btn-sm ml-1"
          color="danger"
          v-c-tooltip.hover="{
            content: `Cancelar`,
          }"
        >
          <i class="fas fa-trash"></i> Cancelar Cita
        </CButton>
      </CCol>
    </CRow>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cerrar</CButton>
    </template>
  </CModalDev>
</template>

<script>
import CModalDev from "../../components/shared/modals/cModalDev.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "c-modal-delete",
  components: { CModalDev, vSelect },
  props: {
    title: String,
    boo_modal: Boolean,
    data: [],
    size: String,
  },
  computed: {
  },
  data() {
    return {
      
    };
  },
  methods: {
    mtd_close() {
      this.$emit("close_proccess");
    },
    mtd_commit() {
      this.$emit("mtd_commit_proccess");
    },
    /** process */
    mtd_proccess: function(newState){
      this.$emit("mtd_commit_proccess",newState,this.data.id);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>